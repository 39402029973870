import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Person from '../../Person/Person'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Content from '@solid-ui-blocks/Content/Block02'
import Gallery from '@solid-ui-blocks/Blog/Block01'
import Team from '@solid-ui-blocks/Hero/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import ServicesDetails from '@solid-ui-blocks/Faq/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'
import styles from './_styles'

const Services03 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo 
        title='Tratamentos Dentários em Ceilândia - Realize Sorrisos' 
        description='Implante Dentário, Facetas em Resina, Aparelhos Ortodônticos. O que você precisa, você encontra aqui na Realize Sorrisos Ceilandia!'/>
      <Person type='dentist' />
      <Person type='person' />
      <Header content={content['header']} />
      <Divider space='6' />
      <Container variant='wide' sx={styles.heroContainer}>
        <Content content={content['hero']} />
        <Divider space='3' />
        <Gallery content={content['gallery']} />
      </Container>
      <Divider space='5' />
      <ServicesDetails content={content['services-details']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.teamContainer}>
        <Team content={content['team']} />
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query tratamentos {
    allBlockContent(
      filter: { page: { in: ["realize/tratamentos", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Services03
